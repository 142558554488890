.catalog-product-view {
    #header {
        background: #fff;
    }


    #gallery {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 50px;
        flex-wrap: wrap;

        img {
            background-color: $cartBg;
        }

        .js_thumbs_slide {
            img {
                width: auto;
            }
        }

        .gallery-close-button {
            right: 15px;
            top: 15px;
        }
    }

    .line1-slider-container .container {
        padding: 0;
    }

    .description-head-container, .description-head-container-without-border {
        padding: 10px 0;

        h2 {
            font-size: 1rem;
        }
    }

    .description-head-container {
        border-bottom: 1px solid $border-gray;
    }

    .product-info-list,
    .accordion-block-section {
        border-bottom: 1px solid $border-gray;
    }

    .accordion-block-section .card {
        border: unset;
        box-shadow: unset;
        padding: 10px 0;
    }

    .accordion-wrapper #product-details {
        margin-top: 30px;
    }

    .product-info-list {
        svg {
            max-width: 30px;
            margin-right: 10px;
        }
        li {
            margin-bottom: 15px;
        }
        a {
            &:hover {
                span {
                    transition: all .3s;
                    border-bottom: 2px solid $lilou-black;
                }
            }
            span {
                transition: all .3s;
                display: inline-block;
                font-weight: 500;
                border-bottom: 2px solid transparent;
            }
        }
    }

    #klarna-kec-placeholder {
        display: none;
    }

    #add-to-wishlist,
    #product-page-mollie-apple-pay-button {
        order: 2;
        color: $lilou-black;
        padding: 16px 30px;
        border: 2px solid $lilou-black;
        background-color: transparent;
    }

    #product-page-mollie-apple-pay-button {
        order: 3;
        text-align: center;
    }

    .head-product-title {
        align-items: baseline;

        h1 {
            font-size: 20px;
            line-height: 22px;
            font-weight: 600;
            margin-right: 15px;
        }

        .price-label {
            display: none;
        }

        .final-price span {
            font-size: 20px;
        }
    }

    .product-options-bottom #bundleSummary,
    .product-options-bottom .fieldset-bundle-options .hidden {
        display: none;
    }

    .variant-element {
        max-width: 75px;
        display: inline-block;
    }

    .border-black-500 {
        border: 1px solid $lilou-black;
    }

    fieldset label {
        margin-top: 0;
    }

    .custon-options-grid .option-tile {
        border: 1px solid $lilou-white;
        padding: 10px;
        text-align: center;
        transition: border-color 0.3s;

        &:hover,
        &.selected {
            border-color: $lilou-black;
        }

        img {
            max-height: 100px;
            object-fit: cover;
        }

        span {
            margin-top: 0;
            font-size: 14px;
        }
    }

    .gallery-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        .gallery-image-container {
            width: 100%;
            padding-top: 100%;
            position: relative;

            .gallery-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
            }
        }
    }

    .gallery-mobile-slider {
        display: none;
        @include respond-to('sm') {
            display: block;

            .relative {
                position: relative;
            }
        }

        button {
            background-color: $lilou-white;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 0 5px;

            &.bg-gray-800 {
                background-color: $hoverBtn;
            }
        }
    }

    @include respond-to('sm') {
        .gallery-grid {
            display: none;
        }
    }

    .copy-sku-image {
        width: 15px;
    }
    .gallery-z-index {
        z-index: 1000;
    }
}

.product-configurator-section {
    position: relative;

    nav {
        button {
            font-size: 13px;
            color: $lilou-black;
        }
    }

    .upload-area {
        padding: 15px;
    }

    .close-button {
        right: 30px;
    }

    div[x-show="menuOpen"] {
        z-index: 999;
        transition: .5s all;

        > div {
            height: 100vh;

            > div {
                overflow: hidden;

                form {
                    overflow: scroll;
                }
            }
        }
    }

    .configurator-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;

        .configurator-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border: 1px solid $lilou-black;
            padding: 16px;
            background-color: #fff;
            cursor: pointer;
            width: 100%;
            max-width: 600px;

            .button-content {
                display: flex;
                align-items: center;
                width: 100%;

                .button-image img {
                    width: 80px;
                    height: 80px;
                }

                .button-text {
                    flex-grow: 1;
                    padding: 0 10px;
                    justify-content: flex-start;
                    align-items: center;
                    display: flex;

                    h2 {
                        margin: 0;
                        font-size: 18px;
                    }

                    p {
                        margin: 0;
                        font-size: 14px;
                    }
                }

                .button-icon {
                    font-size: 24px;
                }
            }
        }
    }

    .video-link {
        display: block;
        text-align: right;
        margin-bottom: 10px;
        color: $lilou-black;
        text-decoration: underline;
        cursor: pointer;
    }

    .configurator-popup,
    .video-popup {
        display: flex;
        align-items: center;
        justify-content: center;

        .popup-content {
            background: #fff;
            padding: 20px;
            position: relative;
            width: 80%;
            height: 80%;

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    .close-popup {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 24px;
        background: none;
        border: none;
        cursor: pointer;
        color: $lilou-black;
    }

    .hidden {
        display: none;
    }

    .product-configurator-section-footer {
        align-items: center;
    }
}

div[x-show="videoOpen"] {
    > div {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    button {
        position: absolute;
        top: 20px;
        right: 20px;
        color: $lilou-black;
    }

    iframe {
        width: calc(100vw - 80px);
        height: calc(100vh - 80px);
    }
}

.out-of-stock-notification-popup {
    padding: 30px;
    position: relative;

    .header-text {
        padding-right: 40px;
    }

    .close-button {
        position: absolute;
        right: 30px;
        top: 30px;
    }
}

.engrave-option-container {
    .engraving-cost-info {
        border: 1px solid $border-gray;
        padding: 10px;
        display: block;
        width: 100%;
        position: relative;

        svg {
            display: inline-block;
            position: relative;
            top: -2px;
            padding-right: 5px;
        }
    }

    .w-100 {
        width: 100%;
    }

    .engrave-special-characters {
        .engrave-image {
            width: 200px;
            height: 200px;
        }
    }

    .upload-area {
        border: 1px dashed $border-gray;
    }

    .engrave-special-char-button {
        border: 2px solid #fff;

        &.selected-engraving {
            border: 2px solid #000;
        }
    }

    .selected-image-url {
        max-width: 300px;
    }
}

.products {
    ul {
        li {
            .product-info {
                > div {
                    flex: 1;

                    &:first-child {
                        flex-direction: column;
                    }
                }

                .product-item {
                    .price-container {
                        flex: auto;
                    }
                }
            }
        }
    }
}
.popular-categories {

    &__header {
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__item,
    &__subitem {
        a {
            text-decoration: none;
            color: inherit;
            transition: text-decoration 0.3s ease;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__sublist {
        list-style: none;
        padding-left: 1rem;
        margin: 0.5rem 0 0;
    }
}
