.form-input, .form-select {
    border: 0;
    box-shadow: unset;
    border-bottom: 1px solid #000;
    border-radius: 0;
    width: 100%;
    background: transparent;
    height: 44px;
    min-height: 0;
    padding: 8px 8px 8px 0;
    &:focus {
        box-shadow: unset;
        border-bottom: 2px solid #000;
    }
}
.btn-primary, a.pagebuilder-button-primary, button.pagebuilder-button-primary, div.pagebuilder-button-primary {
    background: #000;
    color: #fff;
    border: 1px solid #000;
    border-radius: 0;
    width: 100%;
    padding: 15px;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    box-shadow: unset;
    transition: all .3s;
    &:hover {
        background-color: $gray0;
        border: 1px solid $gray0;
        transition: all .3s;
    }

    &:disabled,
    &[disabled] {
        background-color: $border-gray;
        border-color: $border-gray;
        color: #666;
        cursor: not-allowed;
        transition: all .3s;
    }
    span {
        font-weight: 400;
    }
}

.btn-secondary, .md_social_btn {
    background: transparent;
    color: #000;
    border: 1px solid #000;
    border-radius: 0;
    width: 100%;
    padding: 15px;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    box-shadow: unset;
    span {
        font-weight: 400;
    }
}

a.pagebuilder-button-secondary, button.pagebuilder-button-secondary, div.pagebuilder-button-secondary {
    background: #ffffff;
    color: #000;
    border: 1px solid transparent;
    border-radius: 0;
    width: 100%;
    padding: 15px;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    box-shadow: unset;
    transition: all .3s;
    max-width: 320px !important;
    &:hover {
        background-color: $gray0;
        border: 1px solid $gray0;
        transition: all .3s;
    }
    &:disabled,
    &[disabled] {
        background-color: $border-gray;
        border-color: $border-gray;
        color: #666;
        cursor: not-allowed;
        transition: all .3s;
    }
}
fieldset label, form label {
    margin-top: 7px;
    margin-bottom: 0;

    @include respond-to('sm') {
        margin-top: 5px;
    }
}
[type=checkbox], [type=radio] {
    color: black;
}
form .field.field-reserved {
    div.tooltip {
        display: none;
    }
}
