.customer-account-login {
    h2 {
        font-weight: 600;
    }
    .actions-toolbar {
        display: block;
        border: 0;
        a {
            margin-top:15px;
            color: #000;
            text-decoration: none;
            display: inline-block;
        }
    }
    .field.password {
        .control {
            position: relative;
            button {
                position: absolute;
                right: 0;
            }
        }
    }
    .form-login {
        .text-secondary-darker {
            display: none;
        }

    }
    .card {
        padding: 40px;
        border: 1px solid #E5E5E5;
        box-shadow: unset;
        .card-title {
            h2 {
                font-weight: 600;
            }
        }
    }
    .card-continue-as-guest {
        margin-top: 20px;
    }

    #md-login-social {
        display: flex !important;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        .md-login-social {
            display: flex;
            li {
                margin-left: 20px;
            }

            .md_social_btn {
                background: transparent !important;
                color: #000 !important;
                padding: 15px 40px;
                i {
                    display: none;
                }
            }
        }
    }
}
.customer-account-create {
    .field.choice {
        display: none;
    }
    .actions-toolbar {
        margin: 0;
        border: 0;
        .secondary {
            display: none;
        }
        .primary button {
            background: #000;
            color: #fff;
            border: 1px solid #000;
            border-radius: 0;
            width: 100%;
            padding: 15px;
            font-weight: 700;
            text-align: center;
            justify-content: center;
            box-shadow: unset;
            &:hover {
                background-color: $hoverBtn;
                border: 1px solid $hoverBtn;
            }
        }
    }
    .card {
        padding: 40px;
        border: 1px solid #E5E5E5;
        box-shadow: unset;
    }
}
