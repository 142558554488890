$lilou-black: #231F20;
$lilou-white: #ccc;
$hr: #dddddd;
$gray0: #d3d3d3;
$gray1: #718096;
$gray2: #999999;
$border-gray: #E5E5E5;
$cartBg: #FFF8F4;
$hoverBtn: #646464;
$lilou-bg: #fff8f4;
$lilou-cart: #fcca9d;
$lilou-button-active: #FFF7F2;
$cookie-bg: #474747;
$table-bg: #fff;
$table-border: #e0e0e0;
$table-header-bg: #f5f5f5;
$table-header-text: #616161;
$table-caption-text: #424242;
$table-hover-bg: #f9f9f9;
$table-shadow: 0 4px 10px rgba(0, 0, 0, .1);
$text-color: #000;


@mixin respond-to($breakpoint) {
    @if $breakpoint == 'xs' {
        @media (max-width: 639.98px) { @content; }
    } @else if $breakpoint == 'sm' {
        @media (max-width: 767.98px) { @content; }
    } @else if $breakpoint == 'md' {
        @media (max-width: 1023.98px) { @content; }
    } @else if $breakpoint == 'lg' {
        @media (max-width: 1279.98px) { @content; }
    } @else if $breakpoint == 'xl' {
        @media (max-width: 1535.98px) { @content; }
    }
}

@mixin respond-from($breakpoint) {
    @if $breakpoint == 'xs' {
        @media (min-width: 640px) { @content; }
    } @else if $breakpoint == 'sm' {
        @media (min-width: 768px) { @content; }
    } @else if $breakpoint == 'md' {
        @media (min-width: 1024px) { @content; }
    } @else if $breakpoint == 'lg' {
        @media (min-width: 1280px) { @content; }
    } @else if $breakpoint == 'xl' {
        @media (min-width: 1536px) { @content; }
    }
}

@mixin transition($property, $duration: .3s) {
    transition: $property $duration ease-in-out;
}
