body {
    &.checkout-cart-index {
        padding-top: 0;
    }
}

.checkout-default {
    background: $cartBg;
    padding-top: 0;
    #shipping-method-view-standard {
        button {
            font-weight: 400;
        }
    }
    #shipping-details {
        div {
            div {
                div {
                    &:nth-child(3) {
                        background: white;
                        padding: 0;
                    }
                }
            }
        }
        button {
            font-weight: 400;
            outline: none;
            box-shadow: none;
            border: 1px solid black;
        }
    }
    .address-grid {
        border: 1px solid black;
        padding: 16px;
        .address-item {
            display: flex;
            margin-bottom: 16px;
            gap: 16px;
            background: transparent;
            padding: 20px !important;
            &.active {
                background: $lilou-bg;
            }
            input {
                visibility: hidden;
            }
            label {
                padding: 0;
            }
        }
    }
    .column-main {
        background: #fff;
        padding: 30px;
        border: 1px solid $border-gray;
        #quote-actions {
            display: none;
        }
    }
    #quote-summary {
        background: #fff;
        padding: 30px;
        border: 1px solid $border-gray;
        .cart-items-container {
            margin-bottom: 16px;
        }
        .item.grand_total {
            > div {
                margin-bottom: 14px;
                justify-content: space-between;
                align-items: center;
                span {
                    font-weight: 400;
                    font-size: 16px;
                    &:nth-child(2) {
                        font-size: 22px;
                        font-weight: 600;
                    }
                }
            }
        }
        .item.tax {
            display:none;
        }
    }
    #hyva-checkout-main {
        &.step-payment {
            section#shipping {
                display: none;
            }
        }
    }
    .shipping-methods-tabs {
        border: 1px solid $border-gray;
        border-radius: 5px;
        display: inline-flex;
        margin-bottom: 20px;
        @include respond-to('sm') {
            display: flex;
        }
        button {
            padding: 10px 15px;
            @include respond-to('sm') {
                font-size: 14px;
                flex: 1;
            }
            &:nth-child(2) {
                border-right: 1px solid $border-gray;
                border-left: 1px solid $border-gray;
            }
            &.active {
                background: $lilou-button-active;
            }
        }
    }
    #shipping-method-list {
        li {
            margin-bottom: 16px;
        }
        .btn-secondary {
            width: 300px;
            padding: 10px 20px;
            font-weight: 400;
            position: relative;
            left: -40px;
            outline: none;
            box-shadow: none;
            border: 1px solid #000000;
            @include respond-to('sm') {
                width: 100%;
            }
        }
    }
    .block-content-checklist {
        order: 2;
        margin: 30px;
    }
    #payment-method-list {
        li {
            border: 1px solid $border-gray;
            display: inline-block;
            width: 60%;
            margin-bottom: 20px;
            position: relative;

            @include respond-to('lg') {
                width: 100%;
            }
            input {
                display: none;
            }
            label {
                padding: 15px 35px;
                text-align: center;
            }
            &.active {
                border-color: #000;
                label {
                    font-weight: 500;
                }
                &:before {
                    content: url("../../svg/checklist-item.svg");
                    position: absolute;
                    left: 10px;
                    top: 17px;
                }
            }
            &#payment-method-option-payu_gateway {
                border: 0px;
                &.active {
                    &:before {
                        display: none;
                    }
                    button {
                        &.active {
                            border-color: #000;
                            label {
                                font-weight: 500;
                            }
                            &:before {
                                content: url("../../svg/checklist-item.svg");
                                position: absolute;
                                left: 10px;
                                top: 17px;
                            }
                        }
                    }
                }
            }
            .payu-buttons {
                flex-direction: column;
                button {
                    border: 1px solid $border-gray;
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 20px;
                    position: relative;
                    padding: 15px 35px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .field-type-email {
        grid-column: span 12 / span 12;
        button {
            display: none;
        }
    }
    .field-firstname, .field-lastname, .field-street, .field-country_id, .field-postcode, .field-city, .field-telephone, .field-company, .field-vat_id,  .field-region, .field-save {
        grid-column: span 6 / span 6;

        @include respond-to('sm') {
            grid-column: span 12 / span 12;
        }
    }
    .payment-method-note {
        text-align: center;
    }
    .field-mp_smtp_is_synced, .field-country_id {
        display: none;
    }
    .field-save {
        label {
            margin-top: 0;
            font-weight: 400;
        }
    }
    @include respond-to('lg') {
        .field-street {
            grid-column: span 12 / span 12;
        }
    }
    #billing-details {
        margin-top: 0;
        .section-title, address {
            display: none;
        }
        span {
            font-weight: 400;
        }
    }
    .geowidget-modal {
        width: 800px;
        height: 600px;
        padding-top: 2rem;
        .modal-close-button {
            svg {
                width: 25px;
                height: 25px;
            }
        }
    }
    #magewire-loader {
        display: none;
    }
    #payment-method-view-payu_gateway {
        button {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .terms-conditions-checkboxes {
        a {
            text-decoration: underline;
        }
        button {
            display: none;
        }
    }
    .total-segments {
        .subtotal {
            display: none;
        }
        .shipping {
            margin-bottom: 16px;
        }
    }
    .price-summary {
        header {
            margin-bottom: 16px;
        }
        .cart-items-container {
            button {
                > div {
                    font-weight: 400;
                }
            }
            .cart-items {
                .product-title {
                    p {
                        font-weight: 400;
                    }
                    p.text-sm {
                        display: none;
                    }
                }
            }
        }
    }

    .field-wrapper {
        .required {
            span {
                &:after {
                    content: "*";
                    color: red;
                }
            }
        }
    }
    .shipping-loading-placeholder {
        background: rgba(255,255,255, 0.6);
    }

}
.checkout-onepage-success {
    background: $cartBg;
    #header {
        display: none;
    }
    #maincontent {
        padding: 100px 0;
        h1 {
            text-align: center;
            display: block;
            width: 100%;
        }
    }
}
