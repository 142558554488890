//@import "tailwindcss/base";
//@import "tailwindcss/components";
//@import "tailwindcss/utilities";

/* Import Globalnych plików SCSS */
@import "global/mixins";
@import "global/fonts";
@import "global/forms";
@import "global/main";
@import "global/homepage";
@import "global/pages";
@import "global/header";
@import "global/footer";
@import "global/category";
@import "global/blog";
@import "global/product";
@import "global/checkout";
@import "global/cart";
@import "global/minicart";
@import "global/customer";
@import "global/search";
@import "global/account";
@import "global/giftcard";
@import "global/loadingPage";
@import "global/cookies";
@import "global/error-pages";
@import "global/configurator";

