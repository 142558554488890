#maincontent {
    .post-info-wraper {
        h2.mp-post-title a,
        .about-admin h4.admin-title a,
        .mp-post-meta-info a.mp-read-more,
        .mp-post-info a.mp-info,
        .products-same-post a.product-item-link:hover,
        a.mp-relate-link,
        .mpblog-product-name {
            color: unset !important;
        }
    }

    ul.menu-categories a.list-categories:hover {
        color: black !important;
    }

    .post-list-content {
        .limiter {
            display: none !important;
        }

        .flex.justify-center.order-1.col-span-2 {
            display: none !important;
        }
    }
}

.mpblog-category-view, .mpblog-post-index, .mpblog-post-view {
    .sidebar-additional {
        display: none !important;
    }
    .post-item-wraper {
        display: flex;
        flex-direction: column;
        &:hover {
            box-shadow: none !important;
            cursor: pointer;
        }
    }
    .post-info-wraper h2.mp-post-title a, .about-admin h4.admin-title a, .mp-post-meta-info a.mp-read-more, .mp-post-info a.mp-info, ul.menu-categories a.list-categories:hover, .products-same-post a.product-item-link:hover, a.mp-relate-link, .mpblog-product-name {
        color: black !important;
        cursor: pointer;
    }
    .product-item-info:hover, .products-grid .product-item-info.active {
        border: none;
        margin: 0;
        padding: 0;
    }
    .post-image img.img-responsive {
        height: 300px;
    }
    .product-item {
        .mp-blog-slider-item {
            padding: 30px 20px;
        }
        .actions-primary {
            .btn-primary {
                display: flex;
            }
        }
    }
    .product-item-info:hover, .products-grid .product-item-info.active {
        box-shadow: none !important;
    }
}

.mpblog-post-index,
.mpblog-post-view,
.mpblog-category-view {
    .columns {
        width: 100%;
        grid-template-columns: none;
        display: flex;
    }
    .column {
        width: 100%;
        grid-column: none;
        grid-row: none;
    }
    .menu-categories {
        padding: 15px 0;
        border-top: 1px solid $border-gray;
        border-bottom: 1px solid $border-gray;

        i {
            display: none;
        }
    }

    .mp-blog-rss {
        display: none;
    }

    .mp-post-info {
        font-size: 16px;
    }

    .post-list-body {
        display: flex;
        grid-template-columns: none;
        gap: 20px;
        flex-wrap: wrap;

        .product-item {
            width: 100% !important;
            flex: 0 0 calc(50% - 20px);
            &:first-child {
                flex: 0 0 100%;
                .post-image img {
                    height: 600px;
                }
            }
        }

        .post-item-wraper {
            border: 0;

            &:hover {
                box-shadow: unset;
            }
        }

        @include respond-to('sm') {
            grid-template-columns: repeat(1, 1fr);
        }

        .post-info-wraper {
            @include respond-to('sm') {
                padding: 0;
            }
        }

        .post-image {
            min-height: unset;
        }

        .post-link-title {
            @include respond-to('sm') {
                padding-top: 1rem;
                display: block;
                font-size: 20px;
                font-weight: 600;
                line-height: 1.4;
                margin-bottom: 16px;
            }
        }
    }

    .mp-blog-view {
        display: flex;
        flex-direction: column;

        .post-view {
            width: 70%;
        }

        [data-content-type=row][data-appearance=contained] {
            width: 100%;
            max-width: unset;
            padding: 0;
        }

        [data-content-type=row] > div {
            padding: 1rem 0;
        }
        .block-blog-related .related-content-container {
            padding: 0;
        }
        .post-item-wraper {
            padding: 0 !important;
        }
        .post-image img.img-responsive {
            height: 300px;
            object-fit: cover;
        }
        .block-blog-related {
            width: 100% !important;
            margin-top: 30px;
            .splide__slide {
                @include respond-to('md') {
                    width: 100% !important;
                }
            }
            h2 {
                padding: 10px;
            }

            .related-content-container {
                flex-direction: row;

                .post-list-item {
                    width: 100%;

                    .post-image {
                        min-height: unset;
                    }

                    .post-link-title {
                        font-size: 20px;
                    }

                    .mp-post-title {
                        padding: 0;
                    }

                    .post-info-wraper {
                        padding: 20px 0;
                    }
                }
            }
        }
    }
}

.post-item-wraper {
    .post-image {
        img {
            position: relative !important;
            width: 100%;
            height: 440px;
            object-fit: cover;
            @include respond-to('md') {
                height: 220px;
            }
        }
    }
}

ul.menu-categories li.category-item {
    display: inline-block;
    padding: 5px 15px;
}

.mpblog-post-view {
    .mp-blog-view {
        gap: 16px;
        flex-direction: column;
        .post-view {
            width: 100%;
        }
        .block-blog-related {
            @include respond-to('md') {
                width: 100% !important;
            }
        }
        .related-content-container {
            .splide__list {
                display: flex;
                flex-direction: row;
            }

            .splide__arrows {
                display: none;
            }

            .mp-blog-slider-item {
                min-height: 0 !important;
                @include respond-to('md') {
                    width: 100% !important;
                }
            }

            .product-item-link {
                font-weight: 400 !important;
            }
        }
    }
}

@include respond-to('sm') {
    .custom-category-tree ul {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        white-space: nowrap;
    }
    .custom-category-tree ul::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    .custom-category-tree ul li {
        flex: 0 0 auto;
        margin-right: 10px;
    }
    .custom-category-tree ul li:last-child {
        margin-right: 0;
    }
}
