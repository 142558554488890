.close-button-modal {
    position: absolute;
    padding: 10px;
    top: 20px;
    z-index: 9;
    left: 20px;
    background: white;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, .3);
}

.configurator-app {
    @include respond-to('md') {
        overflow: auto;
    }

    .price-modal-box {
        position: fixed;
        bottom: 0;
        background: white;
        margin: 0;
        left: 0;
        padding: 20px;
        z-index: 999;
        width: 100%;
        box-shadow: 0 5px 20px 20px rgba(0, 0, 0, .05)
    }

    .configurator-price {
        font-size: 28px;
    }

    .left-panel {
        padding: 40px;
        background: $cartBg;
        @include respond-to('md') {
            padding: 50px;
        }
    }

    .right-panel {
        padding: 40px;
        @include respond-to('md') {
            padding: 0 0 120px 0;
        }

        .list-buttons {
            gap: 10px;
            @include respond-to('md') {
                display: flex;
                flex-direction: column;
            }
        }

        .tab-content {
            .material-list {
                margin: 0 0 40px 0;
            }

            img {
                width: 80px;
                height: 80px;
                background: $lilou-button-active;
                object-fit: contain;
            }

            .charm-element {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
                padding: 10px;
            }
        }

        .summary-data-element {
            &.summary-data-element-border {
                border-bottom: 1px solid $border-gray;
            }

            padding-bottom: 20px;
            margin-bottom: 20px;

            .summary-data-text {
                p {
                    width: 90%;
                }
            }

            .trash-and-price {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                gap: 5px;
            }
        }

        .is-active-product {
            .items-start {
                margin-top: 20px;
            }
        }
    }

    .engraving-scroll {
        overflow: auto;
        max-height: 5500px;
        @include respond-to('md') {
            max-height: none;
        }

        > nav {
            @include respond-to('md') {
                display: flex;
                flex-wrap: nowrap;
                overflow: hidden;
                white-space: nowrap;
                overflow-x: auto;
                margin-top: 20px;
                scrollbar-width: none; /* Firefox */
                -ms-overflow-style: none; /* IE i Edge */
                &::-webkit-scrollbar {
                    display: none; /* Chrome, Safari */
                }

            }
        }
    }

    .engraving-option-box {
        width: calc(50% - 10px);
        float: left;
        @include respond-to('md') {
            clear: both;
            width: 100%;
        }
    }

    .engraving-option-box:nth-child(even) {
        margin-right: 20px;
        @include respond-to('md') {
            margin-right: 0;
            width: 100%;
        }
    }

    .product-configurator-mapped-products-element {
        width: 82px;
        height: 82px;

        &.selected-element {
            border: 1px solid #000;
        }
    }

    .render-image-container {
        position: relative;
        margin: 0;
        display: flex;
        max-height: calc(100% - 100px);
        @include respond-to('md') {
            max-height: 500px;
        }

        .slot-element {
            position: absolute;
            border: 1px dashed #000;
            border-radius: 50%;
            cursor: pointer;
            //width: min(5rem, 15vw);
            //height: min(5rem, 15vw);
            width: 12% !important;
            height: 12% !important;

            &.active {
                border: 1px solid #000;
            }

            &.selected {
                border: 0;
            }
        }
    }

    .tabs-container {
        @include respond-to('md') {
            padding: 10px;
        }
        > nav {
            @include respond-to('md') {
                display: flex;
                flex-wrap: nowrap;
                overflow: hidden;
                white-space: nowrap;
                overflow-x: auto;
                background-color: $cartBg;
                scrollbar-width: none; /* Firefox */
                -ms-overflow-style: none; /* IE i Edge */
                &::-webkit-scrollbar {
                    display: none; /* Chrome, Safari */
                }

            }
        }
    }

    .tabs-container-menu {
        background: $cartBg;

        .border-active {
            border-top: 2px solid rgb(252, 202, 157)
        }
    }

    .swatch-attribute-options {
        .swatch-option {
            padding: 5px 10px;

            &.ring {
                --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
                --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
                box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
            }

            &.ring-primary\/50 {
                --tw-ring-color: rgb(0 0 0);
            }
        }
    }

    .grid-charms-columns {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        max-height: calc(100vh - 550px);
        @include respond-to('md') {
            max-height: 100vh;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    div[x-show="showCharmSelection"] {
        > div {
            gap: 20px;
        }
    }
}
