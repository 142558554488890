#header {
    position: fixed;
    z-index: 20;
    background: transparent;
    box-shadow: unset;
    border: 0;
    border-bottom: 1px solid #E2E8F0;
    transition: all .3s;
    .header-logo-box {
        @include respond-to('md') {
            position: absolute;
            background: white;
        }
    }
    .header-nav-box {
        position: relative;
        z-index: 999;
    }
    .header-logo {
        svg {
            @include respond-to('md') {
                position: relative;
                margin-left: -82px;
                height: 24px;
            }
        }
    }
    .sticky-header-content {
        padding: 8px;
    }
    input[type="search"]::-webkit-search-cancel-button {
        display: none;
    }
    .aa-Panel {
        display: flex;
        > div {
            flex: 1;
            > div {
                width: 100%;
            }
            > div:nth-child(2) {
                display: none;
            }
        }
        ul {
            overflow: auto;
            width: 500px;
        }
        li.aa-Item {
            width: 25%;
        }
    }
    .ninjamenus-hamburger-trigger {
        background: unset;
    }
    .ninjamenus-mobile-accordion.ninjamenus-mobile {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        background: #fff;
        color: #000;
        a {
            color: #000;
        }
    }
    .magezon-builder-preload .mgz-element {
        @include respond-to('md') {
            border-top: 1px solid #E2E8F0;
            .mgz-element-text {
                border: none;
            }
            .inner-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                > div {
                    width: 100%;
                }
            }
        }
    }
    &:hover {
        background: white;
        transition: all .3s;
        .ninjamenus {
            span {
                color: black;
                font-weight: 400;
            }
        }
        .header-logo {
            svg {
                @include respond-to('md') {
                    position: relative;
                    margin-left: -82px;
                }
                path {
                    transition: all .3s;
                    fill: black;
                }
            }
        }
        svg {
            color: black;
        }
        .nav-item .caret {
            color: black;
        }
    }
    .mgz-child:not(:last-child) > .mgz-element-inner {
        @include respond-to('md') {
            padding-left: 0 !important;
        }
    }
    > .container {
        padding-bottom: 0;
        @include respond-to('md') {
            position: absolute;
            top: 30px;
            padding: 0 12px;
            max-width: none;
            display: flex;
            align-content: center;
            justify-content: center;
            height: 100%;
        }
        &.sticky-bar-enabled {
            @include respond-to('md') {
                top: 22px;
            }
        }
    }
    .ninjamenus-hamburger-trigger {
        .menu-trigger-inner {
            &:hover {
                color: black;
                background: transparent;
                .line {
                    background: black;
                    height: 1px;
                    margin-bottom: 5px;
                }
            }
        }
        .trigger-icon {
            color: black;
            background: transparent;
            .line {
                background: black;
                height: 1px;
                margin-bottom: 5px;
            }
        }
    }
    .ninjamenus-hamburger-active {
        .menu-trigger-inner {
            color: black;
            background: transparent;
            &:hover {
                color: black;
                background: transparent;
                .line {
                    background: black;
                    height: 1px;
                    margin-bottom: 5px;
                }
            }
            .line {
                background: black;
                height: 1px;
                margin-bottom: 5px;
            }
        }
    }
    .ninjamenus, .magezon-builder {
        position: unset;
    }
    .ninjamenus {
        .opener {
            &:hover {
                background: transparent;
            }
        }
    }
    .ninjamenus {
        .item-submenu {
            padding: 30px;
            @include respond-to('md') {
                padding: 0 15px;
            }
        }
    }
    #ninjamenus2 {
        > div {
            display: flex;
            align-items: center;
            @include respond-to('md') {
                align-items: flex-start;
                flex-direction: column;
            }
        }
    }
    .ninjamenus .level0>a>span.title {
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        height: 50px;
        font-size: 15px;
        @include respond-to('md') {
            height: auto;
        }
    }
    .level0 {
        a {
            align-items: center;
            column-gap: 10px;
            justify-content: center;
            margin-top: 6px;
            padding: 0 7px;
            @include respond-to('md') {
                padding: 0 15px !important;
            }
        }
        &:hover > a {
            background-color: transparent !important;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 5px;
                width: 100%;
                background: black;
                z-index: 1;
            }
            @include respond-to('md') {
                &::after {
                    display: none;
                }
            }
        }
    }
    strong {
        font-weight: 400;
        @include respond-to('md') {
            font-weight: 600;
        }
    }
    .magezon-builder .mgz-container {
        max-width: 1536px;
    }
    button {
        &:hover {
            background-color: transparent !important;
        }
    }
    .tabular-nums {
        background: $lilou-cart;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        z-index: 1;
        border-radius: 50%;
        font-size: 11px;
        position: absolute;
        top: -7px;
        right: -14px;
        color: $lilou-black;
    }
    .item-submenu {
        > div {
            width: auto;
        }
        a {
            color: #000;
            span {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: -5px;
                    width: 0;
                    height: 2px;
                    background-color: currentcolor;
                    transition: width .3s, left .3s;
                    transform-origin: center center;
                }
                &:hover::after {
                    width: 100%;
                    left: 0;
                }
            }
        }
    }

    .fixed {
        z-index: 999;
        a {
            @include respond-to('md') {
                color: #000;
            }
        }
        .search-container {
            a {
                color: #000;
            }
        }
        button {
            > div {
                border-color: transparent;
            }
        }
    }
    .cls-1 {
        fill: #000;
    }

    #customer-menu, #menu-cart-icon, #menu-search-icon {
        @include respond-to('md') {
            margin-top: -6px;
        }
    }

    &.scrolled-header {
        background-color: #fff;
        .container {
            a {
                color: #000;
                &.btn-primary {
                    color: #fff;
                }
            }
        }
        .sticky-header-content {
            color: #fff;
            &:hover {
                span {
                    color: #fff;
                }
            }
        }
        #customer-menu, #menu-cart-icon, #menu-search-icon {
            svg {
                color: #000;
            }
        }
        .cls-1 {
            fill: #000;
        }
    }

}
.block {
    > nav {
        @include respond-to('md') {
            display: none;
        }
    }
}
body {
    .cms-content .block.ninjamenus-widget > .ninjamenus, .block.ninjamenus-widget > .ninjamenus {
        background: unset;
    }
}
.cms-home .magezon-menu-desktop .ninjamenus a {
    color: #fff;
}
.page-products {
    #header {
        .level0 {
            a {
                color: #000;
            }
        }
    }
}

body .aa-Panel {
    border: 0 !important;
    margin-top: 0;
    right: 0 !important;
    left: 0 !important;

    #autocomplete-products-footer {
        display: none;
    }
}

nav {
    .tabular-nums {
        right: 20px !important;
    }
}

@media (min-width: 769px) {

    body .aa-Panel {
        border: 0 !important;
        margin-top: 0;
        right: 0 !important;
        left: 0 !important;

        #autocomplete-products-footer {
            display: none;
        }
    }
}
