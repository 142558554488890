* {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: unset;
}

::-webkit-scrollbar-thumb {
    background-color: $border-gray;
    outline: unset;
    border-radius: 10px;
}

svg {
    path {
        stroke-width: 1px;
    }
}

body {
    font-family: 'Syne', sans-serif;
    background: #fff0;
}

.hide {
    overflow: hidden;
    --removed-body-scroll-bar-size: 15px;
    overscroll-behavior: contain;
}

.line1-slider-container {
    margin-bottom: 100px;

    .title-font {
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;
        @include respond-to('md') {
            font-size: 36px;
            line-height: 40px;
        }

        .slider-buttons {
            margin-top: 10px;
            @include respond-to('md') {
                svg {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    .product-info {
        .mt-auto.pt-3.flex.flex-wrap.items-center {
            display: none;
        }
    }

    .product-item-link {
        font-weight: 400;
        font-size: 16px;
        text-align: left;
        display: block;
    }
}

.checklist {
    list-style-type: none;

    li {
        position: relative;
        padding-left: 40px;
        margin-bottom: 15px;

        &:before {
            content: url("../../svg/checklist-item.svg");
            position: absolute;
            left: 5px;
            top: 3px;
        }
    }
}

.grid.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.personalization-icon, .setup-config-icon {
    img {
        height: 25px;
    }
}

.checkout-cart-index .cart.item .message.error {
    background-color: rgb(239 68 68);
    color: #fff;
    border: unset;
    box-shadow: unset;

    svg {
        color: #fff;
    }
}

.message.error {
    background-color: rgb(239 68 68);
    color: #fff;

    svg {
        color: #fff;
    }
}

.message.success {
    background-color: rgb(16 185 129);
    color: #fff;

    svg {
        color: #fff;
    }
}

.checkout-cart-index .cart.item .message.success {
    background-color: rgb(16 185 129);
    color: #fff;
    border: unset;
    box-shadow: unset;

    svg {
        color: #fff;
    }
}

.loader-image {
    max-height: 100px;
}

body {
    .ec-gtm-cookie-directive > div {
        border-radius: 0px;

        > div > div a.action.accept-all, > div > div a.action.accept {
            background: #000;
            color: #fff;
            border: 1px solid #000;
            border-radius: 0;

            padding: 15px;
            font-weight: 700;
            text-align: center;
            justify-content: center;
            box-shadow: unset;

            &:hover {
                background-color: $hoverBtn;
                border: 1px solid $hoverBtn;
            }

            &:disabled,
            &[disabled] {
                background-color: $border-gray;
                border-color: $border-gray;
                color: #666;
                cursor: not-allowed;
            }
        }

        > div > div a.action.decline, > div > div a.action.customize {
            background: transparent;
            color: #000;
            border: 1px solid #000;
            border-radius: 0;
            padding: 15px;
            font-weight: 700;
            text-align: center;
            justify-content: center;
            box-shadow: unset;
        }

        > div > div .ec-gtm-cookie-directive-note-toggle {
            color: #000;
            font-weight: 700;
        }
    }

    .pagebuilder-column-line {
        gap: 50px;
    }
    #algoliaRecommend .recommend-component {
        margin-bottom: 0;
    }
    .c-sidebar {
        padding-right: 10px;
    }
    #messages {
        position: fixed;
        bottom: 0;
        max-width: 460px;
        transition: all .3s;
        .messages {
            display: flex;
            flex-direction: column-reverse;
            .message {
                align-items: flex-start;
                svg {
                    transform: scale(1.2);
                }
            }
        }
    }

    .block-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 30px;

        > * {
            font-weight: 400 !important;
        }

        .action {
            background: #000;
            color: #fff;
            border: 1px solid #000;
            border-radius: 0;
            width: 100%;
            padding: 15px;
            max-width: 320px;
            text-decoration: none;
            font-weight: 700;
            text-align: center;
            justify-content: center;
            box-shadow: unset;
            transition: all .3s;
            font-size: 15px;
            &:hover {
                background-color: $gray0;
                border: 1px solid $gray0;
                transition: all .3s;
            }

            &:disabled,
            &[disabled] {
                background-color: $border-gray;
                border-color: $border-gray;
                color: #666;
                cursor: not-allowed;
                transition: all .3s;
            }
            span {
                font-weight: 400;
            }
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
        background-color: $table-bg;
        box-shadow: $table-shadow;
        border-radius: 8px;
        overflow: hidden;
        color: $text-color;
        margin-top: 20px;
        margin-bottom: 20px;

        caption {
            display: none !important;
        }

        thead {
            background-color: $table-header-bg;

            th {
                font-weight: 600;
                text-transform: uppercase;
                color: $table-header-text;
                border-bottom: 2px solid $table-border;
                padding: 16px;
                text-align: left;
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid $table-border;

                &:hover {
                    background-color: $table-hover-bg;
                    @include transition(background);
                }

                &:last-child {
                    border-bottom: none;
                }

                td {
                    padding: 14px;
                    text-align: left;

                    .action {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;
                        justify-content: center;
                        align-items: center;
                        background: transparent;
                        color: #000;
                        border: 1px solid #000;
                        border-radius: 0;
                        width: auto;
                        padding: 10px 25px;
                        font-weight: 400;
                        text-align: center;
                        box-shadow: unset;
                        max-width: 200px;
                        text-decoration: none;
                        &:first-child {
                            margin-bottom: 10px;
                        }

                        &:last-child {
                            background: #000;
                            color: #fff;
                            border: 1px solid #000;
                            border-radius: 0;
                            width: 100%;
                            padding: 10px 25px;
                            font-weight: 700;
                            text-align: center;
                            justify-content: center;
                            box-shadow: unset;
                            transition: all .3s;
                            max-width: 200px;
                            &:hover {
                                background-color: $gray0;
                                border: 1px solid $gray0;
                                transition: all .3s;
                            }

                            &:disabled,
                            &[disabled] {
                                background-color: $border-gray;
                                border-color: $border-gray;
                                color: #666;
                                cursor: not-allowed;
                                transition: all .3s;
                            }
                            span {
                                font-weight: 400;
                            }
                        }

                        span {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    @include respond-to('md') {
        table {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
        }
        thead {
            display: none;
        }
        tbody {
            tr {
                display: flex;
                flex-direction: column;
                padding: 10px;
            }
        }
        td {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 12px;
            justify-content: space-between;
            gap: 10px;
            &:before {
                content: attr(data-th) ": ";
                font-weight: bold;
                text-transform: uppercase;
                color: $table-header-text;
                display: inline-block;
                min-width: 120px;
            }
            .action {
                flex-direction: column;
                align-items: stretch;
                gap: 10px !important;
                max-width: none !important;
                flex: 1 !important;
                padding: 15px !important;
                margin: 0 !important;
            }
            &:last-child {
                &:before {
                    display: none;
                }
            }
        }
    }

    #maincontent {
        margin-top: 70px;
        [data-content-type=heading], [data-content-type=text] {
            h1, h2, h3, h4, h5, h6 {
                margin: 10px 0;
            }
            p {
                margin: 20px 0;
            }
            figure {
                width: 100% !important;
                img {
                    width: 100% !important;
                }
            }
        }
        [data-content-type=image] {
            margin: 20px 0 !important;
            img {
                width: 100% !important;
            }
        }
    }
    .sticky-header-slider {
        .container {
            button {
                margin-top: 10px;
                svg {
                    path {
                        color: white;
                    }
                }
            }
        }
    }
}
