.cms-page-view.cms-header-image {
    #header {
        position: fixed;
        z-index: 20;
        top: 0;
        width: 100%;
        border: none;
        span {
            color: #fff;
        }
        a {
            i {
                color: white;
            }
        }
        svg {
            color: white;
        }
        .header-logo {
            svg {
                path {
                    fill: white;
                }
            }
        }
        &:hover {
            .header-logo {
                svg {
                    path {
                        fill: black;
                    }
                }
            }
        }
        &.scrolled-header {
            span {
                color: #000;
            }
            a {
                i {
                    color: black;
                }
            }
            .header-logo {
                svg {
                    path {
                        fill: black;
                    }
                }
            }
            .magezon-menu-desktop {
                svg {
                    color: black;
                    path {
                        fill: black;
                    }
                }
            }
        }
    }
    .cms-header-images {
        position: absolute;
        top: 0;
        width: 100%;
        .pc {
            display: flex;
            height: 450px;
            align-items: center;
            justify-content: center;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            @include respond-to('md') {
                display: none;
            }
        }
        .mobile {
            display: none;
            position: relative;
            @include respond-to('md') {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 450px;
                img {
                    object-fit: cover;
                    height: 450px;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: -1;
                }
                > div {
                    h1 {
                        color: #fff;
                    }
                    p {
                        color: #fff;
                    }
                }
            }
        }
    }
    .breadcrumbs {
        display: none;
    }
    main {
        padding-top: 450px !important;
        padding-bottom: 30px;
    }
}
.cms-page-view {
    div[data-content-type="row"] {
        > div {
            display: flex;
            flex-direction: row !important;
            @include respond-to('md') {
                flex-direction: column !important;
            }
        }
    }
    .cms-informacje-faq-html {
        .main {
            > div {
                padding: 0;
            }
        }
    }
    .tab-align-left {
        display: flex;
        @include respond-to('md') {
            flex-direction: column;
        }
        ul {
            box-shadow: none;
            display: flex;
            flex-direction: column;
            @include respond-to('md') {
                flex-direction: row;
                overflow-y: auto;
            }
            li {
                background: transparent !important;
                border: none !important;
                border-bottom: 1px solid $border-gray !important;
                border-left: 1px solid $border-gray !important;
                min-width: 320px;
                transition: all .3s;
                cursor: pointer;
                padding: 0;
                @include respond-to('md') {
                    min-width: 0;
                    flex-shrink: 0;
                    white-space: nowrap;
                    border-top: 1px solid $border-gray !important;
                }
                &:first-child {
                    border-top: 1px solid $border-gray !important;
                }
                &:hover {
                    background: $lilou-button-active !important;
                    transition: all .3s;
                }
                &:after {
                    content: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDAgNDAiIGZva3VzYWJsZT0iZmFsc2UiIGNsYXNzPSJjaGFrYS1pY29uIGNzcy0xN3ptdWJtIj48cGF0aCBkYXRhLW5hbWU9IlBhdGggMTAiIGQ9Im0wIDAgNiA2LTYgNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOCwgOCkiPjwvcGF0aD48L3N2Zz4=");
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 24px;
                    height: 24px;
                }
                a {
                    padding: 16px !important;
                    span {
                        font-weight: 400 !important;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    #maincontent {
        .columns {
            padding: 0;
            div[data-content-type=row][data-appearance=contained] {
                padding: 0;
            }
        }
        h1 {
            font-size: 32px;
            font-weight: bold;
        }
        h2 {
            font-size: 28px;
            font-weight: bold;
        }
        h3 {
            font-size: 24px;
            font-weight: bold;
        }
        h4 {
            font-size: 20px;
            font-weight: bold;
        }
        h5 {
            font-size: 18px;
            font-weight: bold;
        }
        h6 {
            font-size: 16px;
            font-weight: bold;
        }
        ul {
            list-style-type: disc;
            padding-left: 20px;
            li {
                font-size: 16px;
                line-height: 1.6;
            }
        }

        a {
            text-decoration: none;
            transition: all .3s;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
                transition: all .3s;
            }
        }
    }
    div[data-content-type="banner"] {
        width: 100%;
        .pagebuilder-banner-wrapper {
            padding: 32px;
        }
    }
    .pagebuilder-column-group {
        width: 100%;
    }

}
.cms-informacje-formy-platnosci-html {
    div[data-content-type=row][data-appearance=contained] {
        > div {
            display: flex !important;
            flex-direction: column !important;
        }
    }
}

body {
    padding-top: 166px;
    @include respond-to('md') {
        padding-top: 70px;
    }
}

.page.messages {
    top: 166px;
    z-index: 16;
    @include respond-to('md') {
        top: 70px;
    }
}
.contact-form {
    width: 100%;
}

.checkout-onepage-success {
    padding: 0;
    height: 100vh;
    #maincontent {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        height: 100vh;
        align-items: center;
        width: 100vw;
        margin: 0;
        justify-content: center;
        text-align: center;
        gap: 16px;
        > div {
            max-width: 620px;
        }
    }
}
