#hyva-checkout-main {
    #breadcrumbs {
        display: none;
    }
    .field-wrapper {
        label {
            span {
                color: $gray1;
                font-size: 14px;
            }
        }
    }
    #shipping-details {
        header {
            margin-bottom: 0;
        }
    }
    #shipping {
        header {
            margin-bottom: 13px;
        }
    }
    .field-login_expiry {
        visibility: hidden;
    }
}
#cart-drawer  {
    .drawer {
        width: 512px;
        @include respond-to('sm') {
            width: 100%;
        }
    }
    .all-cart {
        display: flex;
        padding: 24px;
        @include respond-to('sm') {
            width: 100vw;
        }
    }
    .back {
        position: absolute;
        z-index: 999;
        padding-left: 0;
    }
    header {
        border-bottom: 1px solid $hr;
    }
    .footer-cart-drawer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    .cart-is-empty {
        margin: 80px 0;
    }
    .cart-subtotal {
        border-bottom: 1px solid $hr;
        border-top: 1px solid $hr;
        p {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .all-products {
        padding-bottom: 108px;
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
    }
    .product-box {
        flex: 1;
        padding-right: 10px;
        .product-header {
            display: flex;
            flex-wrap: wrap;
            .product-name {
                flex: 2;
                font-size: 16px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .product-price {
                flex: 1;
                text-align: right;
                span {
                    font-weight: 400;
                    font-size: 16px;
                }
            }
        }
        .form-input {
            width: 32px;
            padding: 0;
            border: unset;
            font-size: 15px;
            -moz-appearance: textfield;
            &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
        img {
            width: 60px;
        }
        input {
            padding: 0 16px;
        }
        .btn-remove-item {
            position: absolute;
            right: 0;
            margin-left: 20px;
            svg {
                stroke-width: 1;
            }
        }
    }
    .cart-show-more {
        margin: 0 0 20px 0;
        padding: 0;
        border-bottom: 1px solid $hr;
        border-top: 1px solid $hr;
        button {
            height: 50px;
            span {
                font-size: 16px;
                text-decoration: none;
            }
        }
        p {
            font-size: 15px;
            color: black;
        }
    }
    .cart-drawer-element, .product-item-details  {
        .qty-button {
            background: transparent;

        }
        .btn-remove-item {
            margin-left: 100px;
            background: transparent;
            color: $lilou-black;
            box-shadow: unset;
        }

    }
    #mollie_applepay_minicart {
        display: none;
    }
    .checkout-button {
        margin-bottom: 15px;
        color: white !important;
    }
}
.cart-menu-container {
    border-bottom: 1px solid $border-gray;
    .cart-menu {
        padding-top: 8px;
        padding-bottom: 8px;
        align-items: flex-end;
        .logo {
            margin: 5px 0;
            max-width: 140px;
            max-height: 19px;
        }
        .icon-back {
            font-size: 8px;
            line-height: 8px;
            transform: scale(2.5);
            position: relative;
            top: -4px;
        }
        .icon-check {
            position: relative;
            width: 30px;
            height: 26px;
            &:before {
                content: url("../../svg/checklist-item.svg");
                position: absolute;
                left: 5px;
                top: 3px;
            }
        }
    }
}
.free-shipping-component-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.checkout-cart-index {
    background: $cartBg;
    form.product-item, div.product-item {
        width: 80% !important;
    }
    #shopping-cart-table {
        > div {
            padding: 16px 29px 16px 16px;
        }
    }
    .cart-wrapper {
        @include respond-to('md') {
            display: flex;
            flex-direction: column;
        }
    }
    .action-delete {
        margin-left: 20px;
    }
    .cart.item {
        border: 1px solid $border-gray;
        background: white;
        padding: 10px;
        margin-bottom: 15px;
        position: relative;
        .item-info {
            display: flex;
            justify-content: space-between;
            @include respond-to('md') {
                flex-direction: column;
                .col.item {
                    flex-direction: row;
                    flex-wrap: unset;
                    @include respond-to('sm') {
                        flex-direction: column;
                    }
                    dt {
                        display: none;
                    }
                }
            }
            > div {
                padding: 0;
                display: flex;
                gap: 16px;
            }
            a {

                &:first-child {

                    max-height: 280px;
                    @include respond-to('md') {
                        max-height: 150px;
                        max-width: 150px;
                    }
                    @include respond-to('sm') {
                        max-height: 100px;
                        max-width: 100px;
                    }
                }

            }
            img.product-image-photo {
                width: 140px;
                height: 140px;
                object-fit: contain;
                background: $lilou-button-active;
                @include respond-to('md') {
                    width: 150px;
                    height: 150px;
                }
                @include respond-to('sm') {
                    width: 100px;
                    height: 100px;
                }
            }

            .cart-price {
                font-size: 18px;
                .price {
                    font-weight: 400;
                }
                span {
                    font-weight: 400;
                    line-height: 1;
                }
            }
            .product-item-details {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include respond-to('md') {
                    padding-right: 70px;
                }
                @include respond-to('sm') {
                    padding-right: 30px;
                }
                .product-item-name {
                    padding-right: 20px;
                }
                a, span {
                    font-weight: 400;
                    background: transparent;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 16px;
                    position: relative;
                    word-break: normal;
                    white-space: pre-wrap;
                    @include respond-to('md') {
                        font-size: 14px;
                    }
                }
                .item-description {
                    color: $gray2;
                    padding-top: 15px;
                    font-size: 12px;
                    margin-bottom: 5px;
                }
                .item-options {
                    margin-top: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    > * {
                        font-size: 14px;
                        line-height: 14px;
                    }
                    dt {
                        margin-bottom: 0;
                        font-weight: 600;
                    }
                    dd {
                        span {
                            font-size: 14px;
                            line-height: 14px;
                        }
                    }
                }
                .control.qty {
                    display: inline-flex;
                    align-items: center;
                    width: 112px;
                    button {
                        background: transparent;
                        border: 0;
                        box-shadow: unset;
                        flex: 1;
                        padding: 8px;
                        width: 40px;
                        height: 40px;
                    }
                    input {
                        border-bottom: none;
                        text-align: center;
                        padding: 0;
                        width: 40px;
                        flex: 1;
                        height: auto;
                        -moz-appearance: textfield;
                        appearance: textfield;
                        pointer-events: none;
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }

                }
                .price-excluding-tax {
                    display: none;
                }
            }
            .col.subtotal {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .item-actions {
                    > div {
                        > div {
                            justify-content: flex-end;
                        }
                    }
                }
                @include respond-to('md') {
                    width: 100%;
                    > div {
                        > div {
                            width: 100%;
                            > div {
                                display: inline-flex;
                                align-items: center;
                                justify-content: space-between;
                                float: right;
                                button {
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }
                > div {
                    @include respond-to('md') {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        position: absolute;
                        bottom: 20px;
                        right: 20px;
                    }
                    &:first-child {
                        @include respond-to('md') {
                            position: absolute;
                            width: auto;
                            top: 16px;
                            right: 16px;
                        }
                    }
                }
            }
            .action-edit {
                display: none;
            }
        }
    }
    #header {
        display: none;
    }
    .mollie-applepay-minicart-button {
        display: none;
    }
    .cart-summary-container {
        background: #fff;
        border: 1px solid $border-gray;
        padding: 1rem 2rem;
        margin-bottom: 1rem;
        #checkout-link-button {
            margin-top: 30px;
            margin-bottom: 0;
        }
        h2 {
            font-size: 16px;
            font-weight: 600;
        }
        @include respond-to('md') {
            h2 {
                display: none;
            }
        }
        #discount-form-toggle, #block-order-comment-heading, #giftcard-button {
            margin-top: 20px;
            color: $lilou-black;
            display: flex;
            align-items: center;
            img {
                display: inline-block;
            }
            span {
                padding-left: 10px;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                flex-wrap: wrap;
                cursor: pointer;
                b {
                    font-weight: 600;
                    display: inline-block;
                    border-bottom: 1px solid $lilou-black;
                }
            }
        }
        #discount-coupon-form {
            box-shadow: unset;
            padding: 0;
            margin-bottom: 0;
            .btn-primary {
                padding: .5rem 1rem;
                font-weight: 500;
                background: $lilou-black;
            }
            .form-input {
                width: 187px;
            }
        }
        #order-comment-form {
            margin-top: 1rem;
            .actions-toolbar {
                border-top: 0;
                margin-top: 0;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid $border-gray;
            }
            .order-comment-input {
                width: 100%;
                border: 1px solid $border-gray;
            }
            .apply-comment {
                background: $lilou-black;
                border: $border-gray;
                border-radius: 0;
                width: 100%;
            }
        }
        .grant-total-info {
            border-top: 1px solid $border-gray;
            border-bottom: 1px solid $border-gray;
            h3 {
                font-size: 20px;
            }
            div {
                font-size: 20px;
            }
        }
    }
    .line1-slider-container {
        margin-bottom: 50px;
        > div {
            > div {
                margin-bottom: 0;
            }
        }

        .product-info {
            .mt-auto.pt-3.flex.flex-wrap.items-center {
                display: none;
            }
        }
        .product-item-link {
            font-weight: 400;
            font-size: 16px;
            text-align: left;
            display: block;
        }
    }
    .slider-buttons img {
        height: 25px;
    }
}
.cart-empty {
    text-align: center;
    padding: 2rem;
    align-items: center;
    h1 {
        font-size: 3rem;
        margin-bottom: 1rem;
        margin-top: 100px;
        font-weight: 700;
    }
    p {
        margin-bottom: 2rem;
        color: #666;
    }
    .actions-toolbar {
        margin-bottom: 1.5rem;
        border: 0;
        max-width: 500px;
        min-width: 350px;
    }
    .additional-links {
        max-width: 500px;
        min-width: 350px;
    }
    .line1-slider-container {
        margin-bottom: 100px;
        .title-font {
            font-size: 50px;
            font-weight: 400;
            line-height: 100px;
            @include respond-to('md') {
                font-size: 36px;
                line-height: 40px;
            }
            .slider-buttons {
                margin-top: 10px;
                @include respond-to('md') {
                    svg {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
        .product-info {
            .mt-auto.pt-3.flex.flex-wrap.items-center {
                display: none;
            }
        }
        .product-item-link {
            font-weight: 400;
            font-size: 16px;
            text-align: left;
            display: block;
        }
    }
}
